<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      absolute
      bottom
      color="secondary"
      center
      text
    >
      Copied to clipboard!
    </v-snackbar>
    <v-row>
      <v-col cols="12" xs="12" md="4">
        <v-subheader>
          <v-icon small class="mr-2">build</v-icon> Tools
        </v-subheader>
        <v-list>
          <v-list-item
            v-for="tool in tools"
            :key="tool.id"
            @click="executeToolFunction(tool.function)"
          >
            <v-list-item-action>
              <v-icon>{{ tool.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ tool.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="exportItem = {}, exportDocumentModal = true">
            <v-list-item-action>
              <v-icon>article</v-icon>
            </v-list-item-action>
            <v-list-item-content >
              <v-list-item-title>Generate Export Document</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-subheader>
            <v-icon small class="mr-2">functions</v-icon> System Functions
          </v-subheader>
          <v-list style="max-height: 65vh; overflow-y: auto">
            <v-text-field
              class="px-2"
              outlined
              prepend-inner-icon="search"
              v-model="searchFunction"
              dense
              clearable
              placeholder="Search"
            ></v-text-field>
            <v-list-item
              v-for="systemFunction in filterVariables"
              :key="systemFunction.id"
              @click="copyFunction(systemFunction)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ systemFunction.name }}
                </v-list-item-title>
                <v-list-item-title style="font-size: 12px">
                  {{ systemFunction.description }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-list>
      </v-col>
      <v-col cols="12" xs="12" md="8">
        <v-toolbar flat>
          <v-icon small class="mr-2">schema</v-icon>
          <v-toolbar-title> System Models </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-text-field
            class="mt-3 pt-3"
            outlined
            prepend-inner-icon="search"
            placeholder="Search"
            v-model="searchModel"
            clearable
          ></v-text-field>
        </v-toolbar>
        <v-list style="height: 65vh; overflow-y: auto" v-loading="loadingModels">
          <v-card outlined v-for="model in filterSharedModels" :key="model.id">
            <v-card-title style="font-size: 16px">{{
              model.friendlyName
            }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4">
                  <v-subheader>Fields</v-subheader>

                  <v-list style="max-height: 30vh; overflow-y: auto" dense>
                    <v-list-item
                      style="height: 20px"
                      v-for="field in model.sharedModelFields"
                      :key="field.id"
                      @click="copyModelField(field)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ field.friendlyName }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <!-- <v-list-item-action>
                        <v-btn icon >
                          <v-icon>content_copy</v-icon>
                        </v-btn>
                      </v-list-item-action> -->
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="4">
                  <v-subheader>Associated Models</v-subheader>
                  <v-treeview
                    style="max-height: 30vh; overflow-y: auto"
                    :items="model.associations"
                    open-on-click
                    transition
                  >
                    <template v-slot:append="{ item }">
                      <v-chip
                        small
                        v-if="
                          item.type == 'belongsTo' || item.type == 'hasMany'
                        "
                      >
                        <span v-if="item.type == 'belongsTo'">Belongs To</span>
                        <span v-else-if="item.type == 'hasMany'">Has Many</span>
                      </v-chip>
                      <v-btn
                        v-else-if="item.type == 'field'"
                        icon
                        @click="copyModelField(item)"
                      >
                        <v-icon>content_copy</v-icon>
                      </v-btn>
                    </template>
                  </v-treeview>
                </v-col>
                <v-col cols="12" md="4">
                  <v-subheader>Functions</v-subheader>

                  <v-list style="max-height: 30vh; overflow-y: auto" dense>
                    <v-list-item
                      v-for="variable in model.systemVariables"
                      :key="variable.id"
                      @click="copyFunction(variable)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ variable.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="variable.description" style="font-size: 12px" class="text-wrap">
                          {{ variable.description }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- -->
        </v-list>
      </v-col>

      <!-- <v-col cols="12" xs="12" md="4">
      
      </v-col> -->
    </v-row>

    <v-dialog v-model="documentLookupModal" persistent width="1200px" :fullscreen="$vuetify.breakpoint.mobile">
      <DocumentLookupTool
        :item="item"
        @close="(documentLookupModal = false), (item = {})"
      />
    </v-dialog>

    <v-dialog v-model="exportDocumentModal" persistent width="750px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            Generate Export Document
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="exportDocumentModal = false, exportItem={}">X</v-btn>
        </v-toolbar>
        <v-card-text class="mb-3">
          <v-autocomplete :items="documents" item-text="name" item-value="id" outlined dense v-model="exportItem.parentDocumentId" label="Select a Document"></v-autocomplete>
          <v-autocomplete v-if="filterGenerateDocuments.length>0" :items="filterGenerateDocuments" item-text="name" item-value="id" outlined dense v-model="exportItem.locationDocumentId" label="Select a Variation Document"></v-autocomplete>
          <v-autocomplete
                      hide-no-data
                      dense
                      style="height: 45px"
                      outlined
                      clearable
                      :loading="loadingContainer"
                      :search-input.sync="searchContainer"
                      v-model="exportItem.containerId"
                      :menu-props="{ closeOnContentClick: true }"
                      label="Search for Container"
                      :items="containers"
                      item-text="containerNo"
                      item-value="id"
                    >
          </v-autocomplete>
          <v-row class="my-3" justify="center">
            <v-btn color="primary" @click="generateDocument()" :disabled="!exportItem.containerId" :loading="generatingDocument">Generate Document</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DocumentLookupTool from "../SystemComponents/DocumentLookupTool.vue";
export default {
  props: ["country", "documents"],
  components: {
    DocumentLookupTool,
  },
  data: () => ({
    containers: [],
    documentLookupModal: false,
    exportDocumentModal: false,
    exportItem: {},
    generatingDocument: false,
    item: {},
    loadingContainer: false,
    loadingModels: false,
    searchFunction: null,
    searchModel: null,
    searchContainer: null,
    selectedTool: null,
    sharedModels: [],
    snackbar: false,
    systemVariables: [],
    tools: [
      {
        id: 1,
        name: "Export Document Lookup",
        icon: "search",
        value: "exportSearch",
        function: "lookupDocument",
      },
      // { id: 2, name: "Test Template", icon: "article", value: "testTemplate" },
    ],
    timeout: null
  }),
  created() {
    this.load();
  },
  watch : {
    async searchContainer(val) {
      if (this.loadingContainer) {
        clearTimeout(this.timeout);
      }
      if (val) {
        this.loadingContainer = true;

        // delay new call 500ms
        this.timeout = setTimeout(async () => {
          this.containers = await this.$API.searchBookingContainer({
            params: {
              search: val
            }
          })
          this.loadingContainer = false;
        }, 500);
      } else {
        clearTimeout(this.timeout);
        this.loadingContainer = false;
      }
    },
  },
  computed: {
    filterSharedModels() {
      let result = this.sharedModels;
      if (this.searchModel) {
        result = result.filter((x) =>
          x.friendlyName.toLowerCase().includes(this.searchModel.toLowerCase())
        );
      }
      return result;
    },
    filterGenerateDocuments() {
      console.log(this.documents)
      let parentDocuments = this.documents
      let result = []
      if(this.exportItem&&this.exportItem.parentDocumentId){
        let parentDocument = parentDocuments.find(x=>x.id==this.exportItem.parentDocumentId)
        result = parentDocument.locationDocumentSubtypes.filter(x=>x.quantityType == 'Shipment')
      }

      return result;
    },
    filterVariables() {
      let result = this.systemVariables;
      if (this.searchFunction) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchFunction.toLowerCase())
        );
      }
      return result;
    },
  },
  methods: {
    copyFunction(item) {
      let text = "${" + item.name + "}";
      navigator.clipboard.writeText(text);
      this.snackbar = true;
    },
    copyModelField(item) {
      let text = "${" + item.value + "}";
      navigator.clipboard.writeText(text);
      this.snackbar = true;
    },

    executeToolFunction(type) {
      switch (type) {
        case "lookupDocument":
          this.lookupDocument();
          break;
      }
    },
    async generateDocument(){
      this.generatingDocument = true
      let documentId = this.exportItem.locationDocumentId? this.exportItem.locationDocumentId: this.exportItem.parentDocumentId

      let file = await this.$API.generateIntegrationDocument(documentId, this.exportItem.containerId);
      var hiddenElement = document.createElement("a");
      hiddenElement.href = file.file;
      hiddenElement.target = "_blank";
      hiddenElement.download = 'test.xlsx';
      hiddenElement.click();
      this.generatingDocument = false
    },
    async getModels() {
      this.loadingModels = true
      let models = await this.$API.getSharedModels();
      this.sharedModels = models;
      this.sharedModels.forEach((x) => {
        x.associations = x.associations.map((x) => ({
          type: x.type,
          name: x.model,
          children: x.fields.map((y) => ({ ...y, type: "field" })),
        }));
      });
      this.loadingModels = false
    },
    async getSystemVariables() {
      this.systemVariables = await this.$API.getGlobalSystemVariables();
    },
    load() {
      this.getSystemVariables();
      this.getModels();
    },
    lookupDocument() {
      this.item = {
        originCountry: this.country,
      };
      this.documentLookupModal = true;
    },
  },
};
</script>
<style scoped>
.v-subheader {
  background: var(--v-component-base);
  font-size: 18px;
}
</style>
